<template>
  <div class="integralHtml">
    <div> 
      <div class="integral" v-for="item in list" :key="item">
        <div class="integralName">
          <span>姓名: {{item.Name}}</span>
          <span>总积分: {{item.TotalIntegral}}</span>
        </div>
        <div class="integralParty">所在党组织: {{item.PartyName}}</div> 
      </div>
    </div>
  </div>
</template>

<script>
import { setOpenId, getOpenId } from "@/utils/auth";
import { WeGetPMIntegralPage } from "@/api/RealInfo";
export default {
  data() {
    return {
      list:[],
    }
  },
  methods:{
    // 获取分页列表
    getList() {
      WeGetPMIntegralPage()
        .then((res) => {
          if (res.data.code == 0) {
            if (res.data.count == 0) {
              this.list = {};
            } else {
              this.list = res.data.data;
            }
          }
        })
        .catch(() => {});
    },
  },
  mounted() {
    if (this.$route.query["openid"]) {
      setOpenId(this.$route.query["openid"]);
    }
    this.getList();
  },
}
</script>

<style>
.integralHtml {
  background-color: rgb(246, 242, 242);
}
  .integral {
    width: 90%;
    margin: 10px 5%;
    background: #fff;
    border-radius: 15px;
  }
  .integralName {

    padding: 15px 0px 8px 15px;
    font-size: 15px;
    overflow: hidden;
  }
  .integralName span:nth-child(1) {
    float: left;
  }
  .integralName span:nth-child(2) {
    float: right;
    margin-right: 15px;
  }
  .integralParty {
    padding-left: 15px;
    padding-bottom: 15px;
    font-size: 14px;
    color: rgb(172, 167, 167);
  }
</style>